import * as web3  from '@velas/web3';
import { Buffer } from 'buffer';

// TO DO: Should replace crypto-hash to package without crypto dependency;
// import {sha256}  from 'crypto-hash';

import { SolidInstruction } from '../helper/relying_party_instructions';

function RelyingPartyProgram() {
    this.provider  = web3;
    this.programId = '3hKvZMgazT7AWJHNuNxMwe1dASenPt4MY7vmmeSJbckT';
};

RelyingPartyProgram.prototype.createAccount = async function(params) {

    // VALIDATE PARAMS

    const fromPubkey  = params.payer_public_key;
    const ownerPubkey = params.owner_public_key;

    // const hash = await sha256(
    //     Buffer.from("".concat(...params.redirect_uris))
    // );

    const hash = '00';

    const [address, nonce] = await this.provider.PublicKey.findProgramAddress([ 
        Buffer.from(params.name).slice(0, Math.min(32, params.name.length)),
        Buffer.from(params.icon).slice(0, Math.min(32, params.icon.length)),
        Buffer.from(params.domain_name).slice(0, Math.min(32, params.domain_name.length)),
        Buffer.from(hash,'hex').slice(0, 32),
    ], new this.provider.PublicKey(this.programId));

    const transfer = this.provider.SystemProgram.transfer({
        fromPubkey,
        toPubkey:   address,
        lamports:   3000000, // TO DO: calc rent
    });

    const init = new this.provider.TransactionInstruction({
        programId: this.programId,

        keys: [
            { pubkey: address,     isSigner: false, isWritable: true },
            { pubkey: ownerPubkey, isSigner: false, isWritable: false },
            { pubkey: new this.provider.PublicKey("SysvarRent111111111111111111111111111111111"), isSigner: false, isWritable: false },
            { pubkey: new this.provider.PublicKey("11111111111111111111111111111111"), isSigner: false, isWritable: false },
        ],

        data: SolidInstruction.initialize({
            name:         params.name,
            icon:         params.icon,
            domain_name:  params.domain_name,
            redirect_uri: params.redirect_uris,
            nonce,
        }).encode(),
    });

    return new this.provider.Transaction().add(transfer, init);
};


export default new RelyingPartyProgram();