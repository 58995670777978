import { Enum, Assignable, SCHEMA } from './solana-borsh';

const BN = require('bn.js');

export class Initialize            extends Assignable {};
export class Execute               extends Assignable {};
export class SponsorAndExecute     extends Assignable {};
export class Transfer              extends Assignable {};
export class CreateAccountWithSeed extends Assignable {};
export class Owner                 extends Assignable {};
export class Operational           extends Assignable {};
export class State                 extends Assignable {};
export class Initialized           extends Assignable {};
export class Frozen                extends Assignable {};
export class Vaccount              extends Assignable {};

export class OperationalStruct extends Assignable {
    static len() {
        return 134;
    };
};

export class VPublicKey extends Assignable {
    static fromBuffer(publicKey) {
        return new VPublicKey({ bytes: Uint8Array.from(publicKey.toBuffer()) });
    };
};

export class SignerType extends Enum {  
    static owner() {
        return new SignerType({ owner: new Owner({}) });
    };
  
    static operational(signerIndex) {
        return new SignerType({ operational: new Operational({ signerIndex }) });
    };
};

export class SolidInstruction extends Enum {
    static initialize() {
        return new SolidInstruction({ initialize: new Initialize({}) });
    };

    static execute(params) {
        return new SolidInstruction({ execute: new Execute({
            programIndex: params.programIndex,
            signerType:   params.signerType,
            executeData:  params.executeData,
        }) });
    };

    static sponsorAndExecute(params) {
        return new SolidInstruction({ sponsorAndExecute: new SponsorAndExecute({
            signerType:   params.signerType,
            executeData:  params.executeData,
            estimatedGas: new BN(params.estimatedGas),
        }) });
    };

    static transfer(params) {
        return new SolidInstruction({ 
            transfer: new Transfer({
                amount:     new BN(params.amount),
                signerType: params.signerType,
            }),
        });
    };

    static createAccountWithSeed(lamports, space, seed) {
        return new SolidInstruction({ 
            createAccountWithSeed: new CreateAccountWithSeed({ lamports, space, seed }),
        });
    };
};

SCHEMA.set(VPublicKey, {
    kind: 'struct',
    fields: [['bytes', [32]]],
});

SCHEMA.set(Vaccount, {
    kind: 'struct',
    field: 'struct',
    fields: [
      ['version', 'u8'],
      ['owners', [32 * 3, VPublicKey]],
      ['genesis_seed_key', VPublicKey],
      ['operational_storage_nonce', 'u16'],
      ['token_storage_nonce', 'u16'],
      ['programs_storage_nonce', 'u16'],
    ],
});

SCHEMA.set(OperationalStruct, {
    kind: 'struct',
    field: 'struct',
    fields: [
      ['pubkey', VPublicKey],
      ['state', State],
      ['agent_type', [32]],
      ['scopes', [4]],
      ['tokens_indices', [32]],
      ['external_programs_indices', [32]],
      ['is_master_key', 'u8'],
    ],
});

SCHEMA.set(SolidInstruction, {
    kind:  'enum',
    field: 'enum',
    values: [
        ['initialize', Initialize],
        ['skip_1',     Initialize],
        ['skip_2',     Initialize],
        ['skip_3',     Initialize],
        ['skip_4',     Initialize],
        ['skip_5',     Initialize],
        ['skip_6',     Initialize],
        ['skip_7',     Initialize],
        ['skip_8',     Initialize],
        ['skip_9',     Initialize],
        ['execute',    Execute],
        ['transfer',   Transfer],
        ['skip_12',    Initialize],
        ['createAccountWithSeed', CreateAccountWithSeed],
        ['skip_14',     Initialize],
        ['skip_15',     Initialize],
        ['skip_16',     Initialize],
        ['skip_17',     Initialize],
        ['skip_18',     Initialize],
        ['skip_19',     Initialize],
        ['sponsorAndExecute', SponsorAndExecute],
    ],
});

SCHEMA.set(Initialize, { kind: 'struct', fields: [] });

SCHEMA.set(Execute, { 
    kind: 'struct', 
    fields: [
        ['programIndex', 'u8'],
        ['signerType', SignerType],
        ['executeData', ['u8']],
    ],
});

SCHEMA.set(SponsorAndExecute, { 
    kind: 'struct',
    fields: [
      ['signerType', SignerType],
      ['executeData', ['u8']],
      ['estimatedGas', 'u64']
    ] 
});

SCHEMA.set(SignerType, {
    kind:  'enum',
    field: 'enum',
    values: [
        ['owner',       Owner],
        ['operational', Operational],
    ],
});

SCHEMA.set(Owner, { kind: 'struct', fields: [] });
SCHEMA.set(Operational, { 
    kind: 'struct', 
    fields: [
        ['signerIndex', 'u8']
    ] 
});

SCHEMA.set(CreateAccountWithSeed, { 
    kind: 'struct',
    fields: [
      ['lamports', 'u64'],
      ['space',    'u64'],
      ['seed',     'String'],
    ] 
});

SCHEMA.set(Transfer, { kind: 'struct', fields: [
    ['amount', 'u64'],
    ['signerType', SignerType],
] });

SCHEMA.set(Initialized, { kind: 'struct', fields: [] });
SCHEMA.set(Frozen, { kind: 'struct', fields: [] });
SCHEMA.set(State, { 
    kind: 'enum',
    values: [
        ['initialize', Initialized],
        ['frozen', Frozen],
    ], 
});
