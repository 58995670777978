import random from '../helper/random';
import Storage from '../helper/storage';
import ttl from '../helper/ttl';

var DEFAULT_NAMESPACE = 'State:';

function TransactionManager(options) {
    var transaction = options.transaction || {};
    this.namespace = transaction.namespace || DEFAULT_NAMESPACE;
    this.keyLength = transaction.keyLength || 32;
    this.storage = new Storage(options);
    this.options = options;
}


TransactionManager.prototype.process = async function(options, authKey) {

    await this.storage.removeExpiredItems();

    var lastUsedConnection = options.realm || options.connection;

    var transaction = await this.generateTransaction(
        options.appState,
        options.state,
        options.nonce,
        lastUsedConnection,
        authKey,
    );

    if (!options.state) {
        options.state = transaction.state;
    }

    if (!options.nonce) {
        options.nonce = transaction.nonce;
    }

    return options;
};

TransactionManager.prototype.generateTransaction = async function(
    appState,
    state,
    nonce,
    lastUsedConnection,
    authKey,
) {
    state = state || random.randomString(this.keyLength);
    nonce = nonce || new Date().getTime();

    await this.storage.setItem(
        this.namespace + state,
        {
            nonce: nonce,
            appState: appState,
            state: state,
            lastUsedConnection: lastUsedConnection,
            authKey,
        },
        { 
            expires: new Date().getTime() + ttl.interaction,
            issuer: this.options.issuer,
        }
    );

    return {
        state: state,
        nonce: nonce
    };
};

TransactionManager.prototype.getStoredTransaction = async function(state) {
    var transactionData;

    transactionData = await this.storage.getItem(this.namespace + state);
    this.clearTransaction(state);
    return transactionData;
};

TransactionManager.prototype.clearTransaction = async function(state) {
    await this.storage.removeItem(this.namespace + state);
};

TransactionManager.prototype.validate = async function(state) {
    var transaction = await this.getStoredTransaction(state);
    var transactionState = (transaction && transaction.state) || null;
    return transactionState === state ? transaction : false;
};

export default TransactionManager;
