import { Enum, Assignable, SCHEMA } from './solana-borsh';

export class Initialize            extends Assignable {};

export class SolidInstruction extends Enum {
    static initialize({
        name,
        icon,
        domain_name,
        redirect_uri,
        nonce,
    }) {
        return new SolidInstruction({ 
            initialize: new Initialize({
                name,
                icon,
                domain_name,
                redirect_uri,
                nonce,
            }),
        });
    };
};

SCHEMA.set(SolidInstruction, {
    kind:  'enum',
    field: 'enum',
    values: [
        ['initialize', Initialize],
    ],
});

SCHEMA.set(Initialize, { 
    kind: 'struct', 
    fields: [
        ['name',         'String'],
        ['icon',         'String'],
        ['domain_name',  'String'],
        ['redirect_uri', ['String']],
        ['nonce',            'u8'],
    ],
});