import * as web3 from '@velas/web3';
import { Buffer }  from 'buffer';

import { SolidInstruction, SignerType, OperationalStruct, Vaccount, Initialized } from '../helper/velas_account_instructions';

const chunkArray = function(myArray, chunk_size) {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    
    for (index = 0; index < arrayLength; index += chunk_size) {
        const myChunk = myArray.slice(index, index+chunk_size).slice(0, 32);
        tempArray.push(myChunk);
    }

    return tempArray;
};

function VelasAccountProgram() {
    this.provider  = web3;
    this.programId = 'VAcccHVjpknkW5N5R9sfRppQxYJrJYVV7QJGKchkQj5';
    this.SYSTEM_PROGRAM_ADDRESS = '11111111111111111111111111111111';
};

VelasAccountProgram.prototype.findAccountAddressWithPublicKey = async function(publicKey, return_base58 = true) {
    const ownerPublicKey = typeof publicKey === 'string' ? new this.provider.PublicKey(publicKey) : publicKey;
    const vaccount = await this.provider.PublicKey.findProgramAddress(
        [ ownerPublicKey.toBuffer().slice(0, 32),
          Buffer.from("vaccount"),
        ],
        new this.provider.PublicKey(this.programId),
    );

    if (return_base58) return vaccount[0].toBase58();

    return vaccount[0];
};

VelasAccountProgram.prototype.getParsedOperationalStorage = async function(operationalStorageNonce, vaccountAddress, connection) {
    const operationalsStorageAddress = await this.getStorageAddress('operationals', vaccountAddress, operationalStorageNonce);
    const operationalsStorageInfo    = await connection.getAccountInfo(operationalsStorageAddress);

    const operationals = [];

    if (operationalsStorageInfo) {
        const number_operational = operationalsStorageInfo.data.length / OperationalStruct.len(); // TO DO: check 
        for (let i = 0; i < number_operational; i++) {
            const start = i * OperationalStruct.len();
            operationals.push(OperationalStruct.decode(operationalsStorageInfo.data.slice(start, start + OperationalStruct.len())));
        };
    };

    return operationals;
};

VelasAccountProgram.prototype.getAccountData = async function(accountPublicKey, connection) {
    accountPublicKey = typeof accountPublicKey === 'string' ? new this.provider.PublicKey(accountPublicKey) : accountPublicKey;

    let account = await connection.getAccountInfo(accountPublicKey);

    const parsed = {
        public_key:      accountPublicKey,
        account_key:     accountPublicKey.toBase58(),

        storage: {
            storage_current:  await this.getStorageAddress('operationals',  accountPublicKey, 1),
            storage_next:     await this.getStorageAddress('operationals',  accountPublicKey, 2),
            programs_current_index: 1,
            tokens_current_index: 1,
        },
    };

    const accountOwner = account ? account.owner.toBase58() : this.programId;
    
    if ((!account || accountOwner === this.SYSTEM_PROGRAM_ADDRESS)) {
        parsed.ephemeral = true;
        return parsed;
    };

    if (account) {
        // Check if account was preinitialized
        const usefulData = account.data.filter(i => i !== 0);
        if (!usefulData.length) parsed.ephemeral  = true;

        const info = Vaccount.decode(account.data);
        account = {
            value: { data: { parsed: { info }}},
            owner: account.owner,  
        };

        account.value.data.parsed.info.owners = chunkArray(account.value.data.parsed.info.owners, 32)
    };

    try {
        parsed.storage = {
            storage_current:  await this.getStorageAddress('operationals',  accountPublicKey, account.value.data.parsed.info.operational_storage_nonce),
            storage_next:     await this.getStorageAddress('operationals',  accountPublicKey, account.value.data.parsed.info.operational_storage_nonce + 1),
            programs_current_index: account.value.data.parsed.info.programs_storage_nonce,
            tokens_current_index:   account.value.data.parsed.info.token_storage_nonce,
        };
    } catch (error) { errorHendler.error("storage processing error", error) };

    const storage = await this.getParsedOperationalStorage(account.value.data.parsed.info.operational_storage_nonce, accountPublicKey, connection );

    try {
        parsed.operational_keys = storage.reduce((acc, curr, index) => {
            const agent_type = Array.from(curr.agent_type).splice(0, Array.from(curr.agent_type).indexOf(0))
            const publickKey = new this.provider.PublicKey(curr.pubkey.bytes);
            
            curr.is_master_key = !!curr.is_master_key;
            curr.index         = index;
            curr.pubkey        = publickKey.toBase58();
            curr.agent_type    = String.fromCharCode.apply(String, agent_type);
            curr.active        = curr.state.initialize instanceof Initialized;
            acc[curr.pubkey]   = curr;

            return acc;
        }, {});
    } catch (error) { errorHendler.error("op_keys storage processing error", error) };

    try {
        parsed.owner_keys = account.value.data.parsed.info.owners.reduce((acc, curr, i) => {
            if (i === 0) acc = [];

            const publickKey       = new this.provider.PublicKey(curr);
            const publickKeyBase58 = publickKey.toBase58();
            
            if (publickKeyBase58 !== this.SYSTEM_PROGRAM_ADDRESS) acc.push(publickKeyBase58);
            return acc;
        }, {});
    } catch (error) { errorHendler.error("owner_keys storage processing error", error) };
 
    return parsed;
};

VelasAccountProgram.prototype.getStorageAddress = async function(storageType, accountPublicKey, version) {
    const publicKey = await this.provider.PublicKey.findProgramAddress([
        accountPublicKey.toBuffer(),
        Buffer.from(Buffer.concat([Buffer.from(storageType), Buffer.from(Uint16Array.of(version).buffer) ])),
    ], new this.provider.PublicKey(this.programId));
    return publicKey[0];
};

VelasAccountProgram.prototype.findProgramIndexInStorage = async function({connection, programsStorage, programId}) {

    const programsStorageInfo         = await connection.getAccountInfo(programsStorage);
    const programsStorageData         = programsStorageInfo ? programsStorageInfo.data : [];
	const programsStorageDataSplited  = chunkArray(programsStorageData, 33);

    for (const i in programsStorageDataSplited) {
        const address = new this.provider.PublicKey(programsStorageDataSplited[i]);
        if (address.toBase58() === programId.toBase58()) return i;
    };

    return 0;
};

VelasAccountProgram.prototype.prepareDataFromStorages = async function({accountPublicKey, connection, sessionKey}) {
    let account      = await connection.getAccountInfo(accountPublicKey);
    let accountOwner = account ? account.owner.toBase58() : this.programId;

    let signerType = SignerType.owner();

    if ( !account || accountOwner === this.SYSTEM_PROGRAM_ADDRESS) {
        return {
            signerType,
            operationalsStorage: new this.provider.PublicKey(this.SYSTEM_PROGRAM_ADDRESS),
            programsStorage:     new this.provider.PublicKey(this.SYSTEM_PROGRAM_ADDRESS),
        };
    };

    const info = Vaccount.decode(account.data);

    account = {
        value: { data: { parsed: { info }}},
        owner: account.owner,  
    };

    if (!account.value.data.parsed.info.programs_storage_nonce    && account.value.data.parsed.info.programs_storage_nonce !== 0)    throw new Error('Programs storage not found');
    if (!account.value.data.parsed.info.operational_storage_nonce && account.value.data.parsed.info.operational_storage_nonce !== 0) throw new Error('Operational storage not found');

    const programsStoragePubKey     = await this.getStorageAddress('programs',     accountPublicKey, account.value.data.parsed.info.programs_storage_nonce);
    const operationalsStoragePubKey = await this.getStorageAddress('operationals', accountPublicKey, account.value.data.parsed.info.operational_storage_nonce);

    if (sessionKey) {
        const key = sessionKey.toBase58();
        const storage = await this.getParsedOperationalStorage(account.value.data.parsed.info.operational_storage_nonce, accountPublicKey, connection);


        const operationalKeys = storage.reduce((acc, curr, index) => {
            const agent_type = Array.from(curr.agent_type).splice(0, Array.from(curr.agent_type).indexOf(0))
            const publickKey = new this.provider.PublicKey(curr.pubkey.bytes);
            
            curr.is_master_key = !!curr.is_master_key;
            curr.index         = index;
            curr.pubkey        = publickKey.toBase58();
            curr.agent_type    = String.fromCharCode.apply(String, agent_type);
            curr.active        = curr.state.initialize instanceof Initialized;
            acc[curr.pubkey]   = curr;

            return acc;
        }, {});
    
        const owner_keys = account.value.data.parsed.info.owners.reduce((acc, curr, i) => {
            if (i === 0) acc = [];
    
            const publickKey       = new this.provider.PublicKey(curr);
            const publickKeyBase58 = publickKey.toBase58();
            
            if (publickKeyBase58 !== '11111111111111111111111111111111') acc.push(publickKeyBase58);
            return acc;
        }, {});
    
        const isOperationalKey = operationalKeys[key];
        const isOwnerKey       = owner_keys.includes(key);

        signerType = isOperationalKey ?  SignerType.operational(isOperationalKey.index) : signerType;
    };

    return {
        operationalsStorage: operationalsStoragePubKey,
        programsStorage: programsStoragePubKey,
        signerType,
    };
};

VelasAccountProgram.prototype.sponsorAndExecute = async function(transactionParams, connectionParams) {

    // VALIDATE PARAMS.

    const {
        operationalsStorage,
        signerType,
    } = await this.prepareDataFromStorages({
        accountPublicKey: transactionParams.fromPubkey,
        connection:       connectionParams.connection,
        sessionKey:       connectionParams.sessionKey,
    });

    const execute = new this.provider.TransactionInstruction({
        programId: this.programId,
        keys: [
            { pubkey: transactionParams.fromPubkey,   isSigner: false, isWritable: true },
            { pubkey: operationalsStorage, isSigner: false, isWritable: true },
            { pubkey: new this.provider.PublicKey("SysvarRent111111111111111111111111111111111"), isSigner: false, isWritable: false },
            { pubkey: new this.provider.PublicKey(this.SYSTEM_PROGRAM_ADDRESS), isSigner: false, isWritable: false },
            { pubkey: new this.provider.PublicKey("EVM1111111111111111111111111111111111111111"), isSigner: false, isWritable: false },
            { pubkey: new this.provider.PublicKey("EvmState11111111111111111111111111111111111"), isSigner: false, isWritable: true },

            { pubkey: transactionParams.sponsorPubKey, isSigner: true, isWritable: true },
            { pubkey: connectionParams.sessionKey,    isSigner: true, isWritable: false },
        ],
        data: SolidInstruction.sponsorAndExecute({
            executeData:  transactionParams.data,
            signerType,
            estimatedGas: transactionParams.estimatedGas,
        }).encode(),
    });

    return new this.provider.Transaction().add(execute);
};

VelasAccountProgram.prototype.execute = async function(transactionParams, connectionParams) {

    // VALIDATE PARAMS.

    const {
        operationalsStorage,
        programsStorage,
        signerType,
    } = await this.prepareDataFromStorages({
        accountPublicKey: transactionParams.fromPubkey,
        connection:       connectionParams.connection,
        sessionKey:       connectionParams.sessionKey,
    });
    
    const programIndex = await this.findProgramIndexInStorage({
        connection: connectionParams.connection,
        programsStorage,
        programId:  transactionParams.keys[0].pubkey
    });

    const execute = new this.provider.TransactionInstruction({
        programId: this.programId,
        keys: [
            { pubkey: transactionParams.fromPubkey,   isSigner: false, isWritable: true },
            { pubkey: operationalsStorage, isSigner: false, isWritable: true },
            { pubkey: programsStorage,     isSigner: false, isWritable: true },
            { pubkey: new this.provider.PublicKey("SysvarRent111111111111111111111111111111111"), isSigner: false, isWritable: false },
            { pubkey: connectionParams.sessionKey, isSigner: true, isWritable: false },
            ...transactionParams.keys,
        ],
        data: SolidInstruction.execute({
            programIndex,
            executeData:   transactionParams.data,
            signerType,
        }).encode(),
    });

    return new this.provider.Transaction().add(execute);
};

// VelasAccountProgram.prototype.createAccountWithSeed = function(params) {

//     const createAccountWithSeed = new this.provider.TransactionInstruction({
//         programId: this.programId,
//         keys: [
//             { pubkey: params.fromPubkey, isSigner: false, isWritable: true },
//             { pubkey: params.storage,    isSigner: false, isWritable: true },
      
//             { pubkey: params.stakePubkey, isSigner: false, isWritable: true },
//             { pubkey: params.fromPubkey,  isSigner: false, isWritable: true },
//             { pubkey: new this.provider.PublicKey("Stake11111111111111111111111111111111111111"), isSigner: false, isWritable: false },
      
//             { pubkey: new this.provider.PublicKey("SysvarRent111111111111111111111111111111111"), isSigner: false, isWritable: false },
//             { pubkey: new this.provider.PublicKey("11111111111111111111111111111111"), isSigner: false, isWritable: false },
      
//             { pubkey: params.session_key, isSigner: true, isWritable: false },
//         ],
//         data: SolidInstruction.createAccountWithSeed(
//             new BN(params.lamports),
//             new BN(200),
//             params.seed,
//         ).encode(),
//     });

//     const stakeInitializeIx = this.provider.StakeProgram.createAccount({
//         fromPubkey: params.fromPubkey, 
//         stakePubkey: params.stakePubkey, 
//         lamports: params.lamports, 
//         authorized: params.authorized, 
//         lockup: params.lockup}
//     );

//     const execute = new this.provider.TransactionInstruction({
//         programId: this.programId,
//         keys: [
//             { pubkey: params.fromPubkey, isSigner: false, isWritable: true },
//             { pubkey: params.storage, isSigner: false, isWritable: true },
//             { pubkey: new this.provider.PublicKey("SysvarRent111111111111111111111111111111111"), isSigner: false, isWritable: false },
      
//             { pubkey: params.session_key, isSigner: true, isWritable: false },
      
//             { pubkey: new this.provider.PublicKey("Stake11111111111111111111111111111111111111"), isSigner: false, isWritable: false },
//             { pubkey: params.stakePubkey, isSigner: false, isWritable: true },
//             { pubkey: new this.provider.PublicKey("SysvarRent111111111111111111111111111111111"), isSigner: false, isWritable: false },
//         ],
//         data: SolidInstruction.execute(1, stakeInitializeIx.instructions[1].data).encode(),
//     });

//     return new this.provider.Transaction().add(createAccountWithSeed, execute);
// };

VelasAccountProgram.prototype.transfer = async function(transactionParams, connectionParams) {

    // VALIDATE PARAMS.

    const {
        operationalsStorage,
        signerType,
    } = await this.prepareDataFromStorages({
        accountPublicKey: transactionParams.fromPubkey,
        connection:       connectionParams.connection,
        sessionKey:       connectionParams.sessionKey,
    });

    const transfer = new this.provider.TransactionInstruction({
        programId: this.programId,
        keys: [
            { pubkey: transactionParams.fromPubkey, isSigner: false, isWritable: true },
            { pubkey: operationalsStorage, isSigner: false, isWritable: true },
            { pubkey: transactionParams.to, isSigner: false, isWritable: true },
            { pubkey: new this.provider.PublicKey("SysvarRent111111111111111111111111111111111"), isSigner: false, isWritable: false },
            { pubkey: new this.provider.PublicKey("11111111111111111111111111111111"), isSigner: false, isWritable: false },
            { pubkey: connectionParams.sessionKey, isSigner: true,  isWritable: false },
        ],
        data: SolidInstruction.transfer({
            amount: transactionParams.lamports,
            signerType,
        }).encode(),
    });

    return new this.provider.Transaction().add(transfer);
};

export default  new VelasAccountProgram();