import jwtDecode  from 'jwt-decode';
import jwkToPem   from 'jwk-to-pem';
import nacl       from 'tweetnacl';
import bs58       from 'bs58';
import { Buffer } from 'buffer';

import ttl from './ttl';

function strToUint8(str) {
    return new TextEncoder().encode(str);
};

function utf8ToBinaryString(str) {
    const escstr = encodeURIComponent(str);
    const binstr = escstr.replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode(parseInt(p1, 16));
    });
    return binstr;
};

function binToUrlBase64(bin) {
    return btoa(bin)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+/g, '');
};

function  strToUrlBase64(str) {
    return binToUrlBase64(utf8ToBinaryString(str));
};

function uint8ToUrlBase64(uint8) {
    let bin = '';
    uint8.forEach((code) => {
        bin += String.fromCharCode(code);
    });
    return binToUrlBase64(bin);
};

const head = {
    "RSASSA-PKCS1-v1_5": {
        alg: 'RS256',
        typ: 'JWT', 
    },
    "ECDSA": {
        alg: 'ES256',
        typ: 'JWT', 
    },
    "ED25519": {
        alg: 'ED25519',
        typ: 'JWT', 
    },
};

function pem(str) {
    return `-----BEGIN PUBLIC KEY-----\n${str}\n-----END PUBLIC KEY-----`
};

function jwkToBase64PubKey(jwk) {
    return jwkToPem(jwk)
        .replace('-----BEGIN PUBLIC KEY-----\n', '')
        .replace('\n-----END PUBLIC KEY-----\n', '')
        .replace( /[\r\n]+/gm, '' );
};

function KeyStorage({ KeyStorageHandler, issuer }) {
    this.handler = new KeyStorageHandler({
        jwkToBase64PubKey,
    });
    this.issuer = issuer;
};

KeyStorage.validateJWT = async function({access_token}) {
    try {
        const { alg }      = jwtDecode(access_token, {header: true});
        const { iss, ses } = jwtDecode(access_token);

        if (!alg || !iss)      throw new Error('payload is not valid');
        if (alg !== 'ED25519') throw new Error('algorithm is not valid');

        const message   = access_token.split('.', 2).join('.');
        const signature = access_token.split('.')[2];
        const publicKey = bs58.decode(ses);
        const body      = access_token.split('.')[1];
        const payload   = JSON.parse(Buffer.from(body, 'base64'));
        const verifyed  = nacl.sign.detached.verify(strToUint8(message), Buffer.from(signature, 'base64'), publicKey);

        if (!verifyed) throw new Error('signature is wrong');

        return payload;
    } catch(e) {
        throw new Error(`Invalid access_token: ${e.message}`);
    };
};

KeyStorage.prototype.signWithKey = function(id, data) {
    return this.handler.signWithKey(id, data)
};

KeyStorage.prototype.parametersToJWT = async function(pubKey, body = {}) {
    try {
        const algorithm    = await this.handler.getAlgorithm(pubKey);
        const jwtHead      = strToUrlBase64(JSON.stringify(head[algorithm]));
        const jwtBody      = strToUrlBase64(JSON.stringify(body));
        const signature    = await this.handler.signWithKey(pubKey, strToUint8(`${jwtHead}.${jwtBody}`));
        const jwtSignature = uint8ToUrlBase64(new Uint8Array(signature))

        return `${jwtHead}.${jwtBody}.${jwtSignature}`;
    } catch (e) {
        throw new Error('jwt token generation error');
    };
};

KeyStorage.prototype.uploadOperationalKey = function({
    keys,
    account,
} = {}) {
    const pair = keys || nacl.sign.keyPair();
    
    return this.handler.uploadKey({
        id:    bs58.encode(pair.publicKey),
        type: 'symmetric-encryption-key',
        issuer: this.issuer,
        expires: new Date().getTime() + ttl.interaction,

        encryptSecret: pair.secretKey,
        account,
    });
};

KeyStorage.prototype.uploadKey = async function() {
    return await this.handler.uploadKey({
        type: 'jwt-signing-key',
        issuer: this.issuer,
        expires: new Date().getTime() + ttl.interaction,
    });
};

KeyStorage.prototype.updateKeyWithAccount = async function(base64PubKey, account) {
    return await this.handler.updateKey(base64PubKey, {
        issuer: this.issuer,
        expires: new Date().getTime() + ttl.interaction,
        account,
    });
};

KeyStorage.prototype.destroy = async function(base64PubKey) {
    return await this.handler.destroy(base64PubKey);
};

KeyStorage.prototype.extract = async function(base64PubKey) {
    return await this.handler.extract(base64PubKey);
};

KeyStorage.prototype.removeExpiredItems = async function() {
    let keys  = await this.handler.loadAllKeys();
    for (var i in keys) { 
        const key = keys[i];
        if (key.issuer && key.issuer === this.issuer) {
            if (!key.account && key.expires && key.expires < new Date().getTime()) {
                await this.handler.destroy(i);
            };
        };
    };

    return await this.handler.loadAllKeys() || {};
};

export default KeyStorage;