import random       from '../helper/random';
import assert       from '../helper/assert';

function PostMessenger(options) {

    assert.check(
        options,
        { type: 'object', message: 'options parameter is not valid' },
        {
            rootUrl:       { type: 'string',  message: 'rootUrl option is required' },
            path:          { type: 'string',  message: 'path option is required' },
            tab:           { type: 'boolean', message: 'tab option is boolean',           optional: true },
            storageAccess: { type: 'boolean', message: 'storageAccess option is boolean', optional: true },
        },
    );

    this.baseOptions = options;

    var _window = window;  

    if (!this.baseOptions.tab) {
        var dialog  = _window.document.createElement('iframe');

        dialog.src          = options.rootUrl + '/' + options.path;
        dialog.className    = "account-iframe";
        dialog.style.width  = "0px";
        dialog.style.height = "0px";
        dialog.style.position = "absolute";
        dialog.style.top    = "0";
        dialog.style.left   = "0";
        dialog.style.zIndex = "1000";
        dialog.onload       = () => { this.ready = true };
        dialog.sandbox      = this.baseOptions.storageAccess ? "allow-popups allow-scripts allow-same-origin allow-storage-access-by-user-activation" : "allow-scripts allow-same-origin";
        _window.document.body.appendChild(dialog);
    
        this.dialog   = dialog.contentWindow;
        this.ready    = false;
    } else {
        this.dialog   = window.open (options.rootUrl + '/preloader', "tab","menubar=1,resizable=1,scrollbars=0,width=500,height=850");
        this.ready    = true;
    }
    
    if   ( _window.addEventListener) { _window.addEventListener("message", this.eventListener.bind(this), false) } 
    else { _window.attachEvent("onmessage", eventListener.bind(this), false) }; // IE 8; 

    this.messages = {};
}

PostMessenger.prototype.show = async function(state, path) {

    if (!this.baseOptions.tab) throw new Error('show available only with tab option');

    this.dialog.location = this.baseOptions.rootUrl + '/' + path;

    this.messages[state] = {
        error: 'invalid_request',
        description: 'no agent response',
    };

    let i = 60 * 10;
    await ( async () => { while (i > 0) {
        this.dialog.postMessage("registration", this.baseOptions.rootUrl);

        await new Promise(resolve => setTimeout(resolve, 1000));
        if (this.messages[state].state) i = 0;
        i--;
    }})();

    return this.messages[state];
};

PostMessenger.prototype.close = function() {

    if (!this.baseOptions.tab) throw new Error('close available only with tab option');

    this.dialog.close();
};

PostMessenger.prototype.eventListener = function(event) {
    if (event.data.state && this.messages[event.data.state]) {
        this.messages[event.data.state] = event.data;
    }
};

PostMessenger.prototype.getStorageAccess = async function() {

    if (this.baseOptions.tab) throw new Error('getStorageAccess available only without tab option');

    const state = random.randomString(32);
    this.messages[state] = {
        error: 'invalid_request',
        description: 'no agent response',
    };

    let i = 100;
    await ( async () => { while (i > 0) {
        await new Promise(resolve => setTimeout(resolve, 100));

        if (this.ready) {
            i = 0;
            this.dialog.postMessage({
                method: 'storage_access',
                state: state,
            }, this.baseOptions.rootUrl);
        }
        i--;
    }})();

    i = 130;
    await ( async () => { while (i > 0) {
        await new Promise(resolve => setTimeout(resolve, 250));

        if (this.messages[state].state && this.messages[state].stage === 'done') {
            i = 0;
        };  i--;
    }})();

    return this.messages[state];
};

PostMessenger.prototype.process = async function(method, payload) {
    const state = random.randomString(32);
    this.messages[state] = {
        error: 'invalid_request',
        description: 'no agent response',
    };

    let i = 100;
    await ( async () => { while (i > 0) {
            await new Promise(resolve => setTimeout(resolve, 100));

            if (this.ready) {
                i = 0;
                this.dialog.postMessage({
                    state,
                    method,
                    ...payload
                }, this.baseOptions.rootUrl);
            }
            i--;
    }})();
    
    i = 130;
    await ( async () => { while (i > 0) {
        await new Promise(resolve => setTimeout(resolve, 250));

        if (i === 128 && this.messages[state].stage === undefined) {
          this.dialog.postMessage({
            state,
            method,
            ...payload
          }, this.baseOptions.rootUrl);
        };

        if (this.messages[state].state && this.messages[state].stage === 'done') {
            i = 0;
        };  i--;
    }})();

    return this.messages[state];
};

export default PostMessenger;
