function isIOS() {
    return !!navigator && !!navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent)
};

function isChrome() {
    return !!window && !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
};

function isSafari() {
    var is_chrome = !!navigator && !!navigator.userAgent && (navigator.userAgent.indexOf('Chrome') > -1);
    var is_safari = !!navigator && !!navigator.userAgent && navigator.userAgent.indexOf("Safari") > -1;
    
    return is_chrome ? false : is_safari
};
  
export default {
    isIOS: isIOS,
    isChrome,
    isSafari
};