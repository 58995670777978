function buildResponse(data, cb = false) {
    if (data.hasOwnProperty('error')) {
        var error = {
            error:       data.error,
            description: data.description,
        };

        if (data.state) { error.state = data.state };

        if (cb) {
            return cb(error);
        } else {
            return error;
        }
    }

    if (cb) {
        return cb(null, data);
    } else {
        return data;
    }
}

function invalidToken(description, cb) {
    return buildResponse({
        error: 'invalid_token',
        description,
    }, cb);
};

function invalidState(description, cb) {
    return buildResponse({
        error: 'invalid_state',
        description,
    }, cb);
};

function failedAuthorization(description, cb) {
    return buildResponse({
        error: 'failed_authorization',
        description,
    }, cb);
};

export default {
    buildResponse,
    invalidToken,
    invalidState,
    failedAuthorization,
};
