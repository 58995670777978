function Storage({ StorageHandler, issuer }) {
    this.handler = new StorageHandler();
    this.issuer  = issuer;
  };
  
  Storage.prototype.getItem = async function(key) {
    return await this.handler.getItem(key);
  };
  
  Storage.prototype.removeItem = async function(key) {
    await this.handler.removeItem(key);
  };
  
  Storage.prototype.setItem = async function(key, value, options) {
    return await this.handler.setItem(key, value, options);
  };
  
  Storage.prototype.getItems = async function(name) {
    const items = await this.handler.getItems() || [];
    const array = [];
    for (var key in items) {
      if (key.indexOf(name) !== -1) {
        try {
          array.push(await this.getItem(key));
        } catch (e) {
          this.warn.warning(e);
          array.push(await this.getItem(key));
        };
      };
    };
  
    return array;
  };
  
  Storage.prototype.removeExpiredItems = async function() {
    const items = await this.handler.getItems() || [];
  
    for (var i in items) {
        const item = items[i];
        if (item.issuer && item.issuer === this.issuer) {
            if (item.expires && item.expires < new Date().getTime()) { 
                await this.handler.removeItem(i);
            };
        };
    };
  };
  
  export default Storage;
  