import objectHelper from './object';

var authorize = [
    'client_id',
    'response_type',
    'mode',
    'redirect_uri',
    'transactions_sponsor_api_host',
    'transactions_sponsor_pub_key',
    'scope',
    'state',
    'nonce',
    'prompt',
    'challenge',
    'csrf_token',
    'idb_supported'
];

function authorizeParams(params) {
    var notAllowed = objectHelper.getKeysNotIn(params, authorize);

    if (notAllowed.length > 0) {
        throw new Error(
            'Following parameters are not allowed on the `authorize` endpoint: [' +
              notAllowed.join(',') +
            ']'
        );
    }

    return params;
}

export default {
    authorizeParams: authorizeParams
};
