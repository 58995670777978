import * as web3  from '@velas/web3';
import qs         from 'qs';
import urljoin    from 'url-join';
import { Keccak } from 'sha3';

import assert              from '../helper/assert';
import detector            from '../helper/detector';
import parametersWhitelist from '../helper/parameters-whitelist';
import KeyStorage          from '../helper/key-storage';
import objectHelper        from '../helper/object';
import error               from '../helper/error';
import TransactionManager  from './transaction-manager';
import PostMessenger       from './post-messenger';
import httpProvider        from './http-provider';
import VelasAccountProgram from '../VelasAccountProgram';
import pkg                 from '../../package.json';

/**
 * Creates a new VAClient API client
 * @constructor
 * @param {Object} options
 */

function VAClient(options = {}) {

    let isNode = false;    
    if (typeof process === 'object') {
        if (typeof process.versions === 'object') {
            if (typeof process.versions.node !== 'undefined') {
                isNode = true;
            }
        }
    };

    options.mode = isNode ? 'backend' : options.mode;

    if (isNode) {
        assert.check(
            options,
            { type: 'object', message: 'options parameter is not valid' },
            {
                clientID:                   { type: 'string',   message: 'clientID option is required' },
                nodeApiHost:                { type: 'string',   message: 'nodeApiHost option is required' },
            },
        );

        this.baseOptions = options;
        this.provider = this;
    } else {
        assert.check(
            options,
            { type: 'object', message: 'options parameter is not valid' },
            {
                clientID:                   { type: 'string',   message: 'clientID option is required' },
                accountProviderHost:        { type: 'string',   message: 'accountProviderHost option is required' },
                redirectUri:                { type: 'string',   message: 'redirectUri option is required' },
                StorageHandler:             { type: 'function', message: 'StorageHandler option is required' },
                KeyStorageHandler:          { type: 'function', message: 'KeyStorageHandler option is required' },
                transactionsSponsorApiHost: { type: 'string',   message: 'transactionsSponsorApiHost option is required' },
                transactionsSponsorPubKey:  { type: 'string',   message: 'transactionsSponsorPubKey option is required' },
                mode:                       { type: 'function', message: 'mode option is required and should be "redirect" or "popup" or "direct" or "mobile"', condition: (o) => { return !['redirect', 'popup', 'direct', 'mobile'].includes(o.mode) }},
                agent:                      { type: 'function', message: 'agent option is required with direct mode', condition: (o) => { return !o.agent && o.mode === 'direct' }},
            },
        );
    
        this.baseOptions         = options;
        this.baseOptions.issuer  = 'client';
        this.baseOptions.rootUrl = this.baseOptions.accountProviderHost.includes("localhost") ? 'http://' + this.baseOptions.accountProviderHost : 'https://' + this.baseOptions.accountProviderHost;
        
        this.baseOptions.needExchangeData = detector.isSafari() || (detector.isIOS() && detector.isChrome()); // safari workaround
    
        this.baseOptions.mode = this.baseOptions.needExchangeData ? 'popup' : this.baseOptions.mode; // safari workaround ( only popup flow );
    
        this.agent = options.mode === 'direct'
            ? options.agent
            : new PostMessenger({
                path:    'iframe',
                rootUrl: this.baseOptions.rootUrl,
                storageAccess: this.baseOptions.needExchangeData, // safari workaround ( allow to send req for storage access );
            });
        
        this.transactionManager = new TransactionManager(this.baseOptions);
        this.keyStorage         = new KeyStorage(this.baseOptions);
        this.provider           = this;
    };
};

VAClient.prototype.verifyMessage = async function(jwt_token) {

    assert.check( jwt_token,   { type: 'string', message: 'jwt_token option required' });
    assert.check( this.baseOptions.nodeApiHost, { type: 'string', message: 'nodeApiHost option required. set nodeApiHost with other initialization parameters.' });

    try {        
        const jwt_token_payload = await KeyStorage.validateJWT({access_token: jwt_token});

        assert.check(
            jwt_token_payload,
            { type: 'object', message: 'jwt_token is not valid' },
            {
                "iss":    { type: 'string', message: 'iss claim value required in the jwt_token' },
                "aud":    { type: 'string', message: 'aud claim value required in the jwt_token' },
                "sub":    { type: 'string', message: 'sub claim value required in the jwt_token' },
                "ses":    { type: 'string', message: 'ses claim value required in the jwt_token' },
                "scopes": { type: 'array',  message: 'scopes value required in the jwt_token'    },
            }
        );

        if (this.baseOptions.clientID !== jwt_token_payload.aud) throw new Error("Audience (aud) value mismatch in the jwt_token");

        const connection = new web3.Connection(this.baseOptions.nodeApiHost, 'singleGossip');
        const account    = await VelasAccountProgram.getAccountData(jwt_token_payload.iss, connection);

        if (account.ephemeral) {
            const derivedAccount = await VelasAccountProgram.findAccountAddressWithPublicKey(jwt_token_payload.ses);
            if (derivedAccount !== jwt_token_payload.iss) throw new Error('jwt_token is not valid: wrong signer');
        } else {
            if (!account.operational_keys[jwt_token_payload.ses])        throw new Error('jwt_token is not valid: wrong signer');
            if (!account.operational_keys[jwt_token_payload.ses].active) throw new Error('jwt_token is not valid: revoked key');
        };

        return {
            account:          jwt_token_payload.iss,
            challenge:        jwt_token_payload.challenge,
            parsed_jwt_token: jwt_token_payload
        };
    } catch(e) {
        throw new Error(e.message);
    };
};

VAClient.prototype.sendTransaction = async function(token, data, cb) {
    if (this.baseOptions.mode === 'backend') throw new Error('Not allowed method for backend');

    const res = await this.agent.process('transaction', { token, data });
    return error.buildResponse(res, cb);
};

VAClient.prototype.signChallange = async function(token, data, cb) {
    if (this.baseOptions.mode === 'backend') throw new Error('Not allowed method for backend');

    const res = await this.agent.process('authorization', { token, data });
    return error.buildResponse(res, cb);
};

VAClient.prototype.sendAsync = httpProvider;

VAClient.prototype.defaultAccount = async function(authResult) {
    if (this.baseOptions.mode === 'backend') throw new Error('Not allowed method for backend');

    this.authResult = authResult;
};

VAClient.prototype.userinfo = async function(token, cb) {
    if (this.baseOptions.mode === 'backend') throw new Error('Not allowed method for backend');

    const res = await this.agent.process('userinfo', { token });
    return error.buildResponse(res, cb);
};

VAClient.prototype.authorize = async function(options = {}, cb) {

    console.log("velas-account version:", pkg.version);

    if (this.baseOptions.mode === 'backend') throw new Error('Not allowed method for backend');

    assert.check(options,           { type: 'object',   message: 'options parameter is not valid' });
    assert.check(cb,                { type: 'function', message: 'cb option is required'});

    let idb_supported = true;

    if (this.baseOptions.mode === 'popup') {
        this.dialog = new PostMessenger({
            rootUrl: this.baseOptions.rootUrl,
            path: 'authorize',
            tab: true,
        });

        if (this.baseOptions.needExchangeData) {
            const result = await this.agent.getStorageAccess();
            console.log("getStorageAccess:", result);
            if (result.error && result.error.includes("invalid security context")) {
                idb_supported = false;
            } else if (result.error) {
                this.dialog.show(0, 'access');
                error.buildResponse({
                    error: 'storage_access',
                    description: 'access not allowed by browser security policies'
                }, cb);
                return;
            }
        } // safari workaround ( send req for storage access );
    };

    if (typeof options.csrfToken === 'function'){
        try {
            options.csrfToken = await options.csrfToken();
        } catch (e) {
            return error.invalidState("csrf endpoint is not available", cb);
        };
    } else {
        assert.check(options.csrfToken, { type: 'string',   message: 'csrfToken option is required for authorize method. Expected function or string.' });
    };

    let params  = objectHelper.merge(this.baseOptions, [ 'clientID', 'redirectUri', 'transactionsSponsorApiHost', 'transactionsSponsorPubKey', 'mode' ]).with(options);

                  await this.keyStorage.removeExpiredItems();
    let authKey = params.mode === 'mobile' 
        ? await this.keyStorage.uploadOperationalKey({type: 'authorization'}) 
        : await this.keyStorage.uploadKey({type: 'authorization'});

        params  = await this.transactionManager.process(params, authKey);

    assert.check(params.scope, { type: 'string', message: 'scope parameter is not valid' });
    
    params.scope = params.scope || 'openid';
    params.idb_supported = idb_supported;

    let response;

    if ( params.mode === 'popup') {
        // const authorize = await this.buildAuthorizeUrl(authKey, params);
        // window.open(authorize.rootQs, "_blank");
        // return;
        const authorize     = await this.buildAuthorizeUrl(authKey, params);
        const dialog_result = await this.dialog.show(params.state, authorize.qs);

        if ( dialog_result.hasOwnProperty('error')) { error.buildResponse(dialog_result, cb); this.dialog.close(); return; };
        if (!dialog_result.hasOwnProperty('code'))  { error.failedAuthorization('no response from agent.', cb); this.dialog.close(); return; };
        if (!dialog_result.hasOwnProperty('state')) { error.invalidState('`state` is not valid.', cb); this.dialog.close(); return; };

        const transaction = await this.transactionManager.validate(dialog_result.state);
        if  (!transaction) { return error.invalidState('`state` does not match.', cb) }

        const token = await this.keyStorage.parametersToJWT(transaction.authKey, {
            code:            dialog_result.code,
            browser_pub_key: transaction.authKey,
            nonce:           new Date().getTime(),
        });

        response = await this.dialog.process('token', { token });

        if (response.access_token && this.baseOptions.needExchangeData)
            await this.agent.process('exchange'); // safari workaround ( send req to exchange localstorage data );

        this.dialog.close();

    } else if (params.mode === 'direct') {
        try {
            const authorize = await this.buildAuthorizeUrl(authKey, params);
            const res = await this.agent.authorize({ token: authorize.str });
            return error.buildResponse(res, cb);
        } catch(e) {
            response = error.failedAuthorization(e.message, cb);
        }
    } else if (params.mode === 'redirect') {
        const authorize = await this.buildAuthorizeUrl(authKey, params);
        window.location = authorize.rootQs;
        return;
    } else if (params.mode === 'mobile') {
        const authorize = await this.buildAuthorizeUrl(authKey, params);
        window.location = authorize.rootQs;
        return;
    };

    return this.validateAuthenticationResponse(response, cb); // refactoring validation
};

VAClient.prototype.buildAuthorizeUrl = async function(authKey, options) {
    assert.check(options, { type: 'object',  message: 'options parameter is not valid' });

    let params;
    params = objectHelper.toSnakeCase(options);
    params = parametersWhitelist.authorizeParams(params);
    
    const token = await this.keyStorage.parametersToJWT(authKey, {
        browser_pub_key: authKey,
        nonce:           new Date().getTime(),
        ...params
    });

    const qString = qs.stringify({token});

    return {
        str:    token,
        qs:     urljoin('authorize', '?' + qString),
        rootQs: urljoin(this.baseOptions.rootUrl,'authorize', '?' + qString),
    };
};

VAClient.prototype.handleRedirectCallback = async function(cb, href = '') {
    if (this.baseOptions.mode === 'backend') throw new Error('Not allowed method for backend');

    assert.check(cb,   { type: 'function', message: 'cb option is required on the `handleRedirectCallback` endpoint'});
    assert.check(href, { type: 'string',   message: 'href parameter is not valid', optional: true});

    const hrefStr = href ? href.split("?")[1] : window.location.href.split("?")[1];

    const parsedQs = qs.parse(hrefStr);
  
    if ( parsedQs.hasOwnProperty('error')) return error.buildResponse(parsedQs, cb);
    if (!parsedQs.hasOwnProperty('code'))  return cb(null, null);
    if (!parsedQs.hasOwnProperty('state')) return error.invalidState('`state` is not valid.', cb);

    const transaction = await this.transactionManager.validate(parsedQs.state);
        if  (!transaction) { return error.invalidState('`state` does not match.', cb) }

    const token = await this.keyStorage.parametersToJWT(transaction.authKey, {
        code: parsedQs.code,
        browser_pub_key: transaction.authKey,
        nonce:           new Date().getTime(),
    });

    if (this.baseOptions.mode === 'mobile') {
        const qString = qs.stringify({token});
        window.location = urljoin(this.baseOptions.rootUrl, 'mobile', '?' + qString);
        return;
    };

    const response = await this.agent.process('token', { token });

    return this.validateAuthenticationResponse(response, cb);
};

/**
 * Validates an VAClient response from a authorize flow started with {@link authorize}
 *
 * Only validates access_tokens signed by Web Crypto Keys;
 *
 * @method validateAuthenticationResponse
 * @param {Object} data an object that represents the parsed hash
 */

VAClient.prototype.validateAuthenticationResponse = async function(params, cb) {
    if (this.baseOptions.mode === 'backend') throw new Error('Not allowed method for backend');
    
    if ( params.hasOwnProperty('error')) { return error.buildResponse(params, cb) };
    try {
        const access_token_payload = await KeyStorage.validateJWT(params);
        assert.check(
            access_token_payload,
            { type: 'object', message: 'access_token payload is not valid' },
            {
                "iss":    { type: 'string', message: 'iss claim value required in the access_token' },
                "aud":    { type: 'string', message: 'aud claim value required in the access_token' },
                "sub":    { type: 'string', message: 'sub claim value required in the access_token' },
                "scopes": { type: 'array',  message: 'scopes value required in the access_token'    },
            }
        );

        if (this.baseOptions.clientID !== access_token_payload.aud) throw new Error("Audience (aud) claim value mismatch in the Access token");

        return error.buildResponse({ ...params, access_token_payload }, cb)
    } catch(e) {
        return error.invalidToken(e.message, cb)
    };
};

VAClient.prototype.accountPublicKeyToEVMAddress = function(accountPublicKey) {
    assert.check(accountPublicKey,   { type: 'string', message: 'value should be a string'});

    accountPublicKey = new web3.PublicKey(accountPublicKey);

    const hash = new Keccak(256);
    const buf  = accountPublicKey.toBuffer();
		  buf._isBuffer = true

    const accountPublicKeyHash = hash.update(buf).digest();
    return "0x" + Buffer.concat([
        Buffer.from([0xAC, 0xC0]),
        accountPublicKeyHash.slice(14, 32)
    ]).toString("hex");
};

export default VAClient;
